import * as React from "react";
interface IDispatch {
  type: string;
}
interface dispatchFunc {
  (params: IDispatch): void;
}


interface IState {

}

export interface IFirm {
  
}

export const FirmContext = React.createContext<IFirm>({
  
});

export const FirmProvider: React.FC<any> = (props) => {
  const [state, dispatch]: [IState, dispatchFunc] = React.useReducer(
    (prevState: any, action: IDispatch) => {
      let obj: any = {};
      switch (action.type) {
        

        default:
          break;
      }
      return {
        ...prevState,
        ...obj,
      };
    },
    {
     
    }
  );

  React.useEffect(() => {
    const fetchTimeSheetReport = async () => {

    };
    fetchTimeSheetReport();
  }, []);

  const firmContext = React.useMemo(
    () => ({
      
    }),
    []
  );
  return (
    <FirmContext.Provider value={firmContext as any}>
      {props.children}
    </FirmContext.Provider>
  );
};

export const withFirmContext = (ChildComponent: any) => (props: any) => {
  let firmProvider = React.useContext(FirmContext);
  return (
    <ChildComponent
      {...props}
      firmProvider={{
        ...firmProvider,
      }}
    />
  );
};
