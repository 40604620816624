import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  isTokenExpired,
  parseJwt,
} from "../../services/UtilService";
import withWidth from "@material-ui/core/withWidth";
import {
  Menu,
  ChevronLeft,
  AccountCircle,
} from "mdi-material-ui";
import { ClassProps, INotification } from "../../vm";
import SideMenu from "./SideMenu";
import { Popover } from "@material-ui/core";
import { CONTACT_EMAIL, NOTIFICATION_TYPES, URL_PATHS } from "../../Constant";
import { withIsReadOnlyContext } from "./IsReadOnlyProvider";
import IsReadOnlyAlert from "./IsReadOnlyAlert";
const drawerWidth = 240;

export interface NavBarProps extends RouteComponentProps, ClassProps {
  width?: string;
  isReadOnly: boolean;
}

export interface NavBarState {
  open: boolean;
  openPopOver: boolean;
  anchorE1: HTMLButtonElement | null;
  anchorE1ForNotifications: HTMLButtonElement | null;
  // userData: any;
  notifications: INotification[];
  showAllNotificationsDialog: boolean;
}

class NavBar extends React.Component<NavBarProps, NavBarState> {
  constructor(props: NavBarProps) {
    super(props);
    this.state = {
      open:
        this.props.width === "sm" || this.props.width === "xs" ? false : false,
      openPopOver: false,
      anchorE1: null,
      anchorE1ForNotifications: null,
      // userData: parseJwt(),
      notifications: [
        {
          message: "25 time entries require attention!",
          type: "attention",
        },
        {
          message: "150 new time entries were added!",
          type: "new entries added",
        },
        {
          message: "5 items require action!",
          type: "action requested",
        },
        {
          message: "2 firms were added!",
          type: "firm added",
        },
        {
          message: "8 users were added!",
          type: "user added",
        },
      ],
      showAllNotificationsDialog: false,
    };
  }
  componentDidMount = () => {
    // this.setUserData();
  };

  setUserData = () => {
    // this.setState({userData: parseJwt()});
  };
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorE1: event.currentTarget,
      openPopOver: true,
      // userData: parseJwt(),
    });
  };
  handleClosePopover = () => {
    this.setState({ anchorE1: null, openPopOver: false });
  };

  handleOpenNotifications = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorE1ForNotifications: event.currentTarget });
  };

  handleCloseNotifications = () => {
    this.setState({ anchorE1ForNotifications: null });
  };
  handleNotificationsDialog = (isOpen: boolean) => {
    this.setState({ showAllNotificationsDialog: isOpen });
  };

  getUserName = () => {
    let userData = parseJwt();
    if (userData.UserName) {
      return userData.UserName;
    } else {
      return "";
    }
  };

  sendEmail = () => {
    window.open("mailto:" + CONTACT_EMAIL);
  };

  onNotificationClick = (type: string) => {
    switch (type) {
      case "attention":
        this.handleCloseNotifications();
        this.props.history.push({
          pathname: `/timesheets-summary`,
          state: {
            status: "attention",
          },
        });
        break;
      case "new entries added":
        this.handleCloseNotifications();
        this.props.history.push({
          pathname: `/timesheets-summary`,
          state: {
            status: "new",
          },
        });
        break;
      case "action requested":
        this.handleCloseNotifications();
        this.props.history.push({
          pathname: `/timesheets-summary`,
          state: {
            status: "attention",
          },
        });
        break;
      case "firm added":
        this.handleCloseNotifications();
        this.props.history.push({
          pathname: `/timesheets-summary`,
        });
        break;
      case "user added":
        this.handleCloseNotifications();
        this.props.history.push({
          pathname: `/access-management`,
        });
        break;
      default:
        this.handleCloseNotifications();
        break;
    }
  };

  render() {
    const { classes, location } = this.props;
    return (
      <>
        {!isTokenExpired() &&
        location.pathname.indexOf(URL_PATHS.DB_DOES_NOT_EXISTS) === -1 ? (
          <>
            <AppBar
              color="default"
              position="absolute"
              className={`${classes.appBar} ${
                this.state.open && classes.appBarShift
              }`}
            >
              <Toolbar
                disableGutters={!this.state.open}
                className={classes.toolbar}
                variant="dense"
              >
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerOpen}
                  className={`${classes.menuButton} ${
                    this.state.open && classes.menuButtonHidden
                  }`}
                >
                  <Menu />
                </IconButton>
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  className={classes.title}
                >
                  <img
                    className={classes.temLogo}
                    src="/tortera_logo.svg"
                    alt="logo"
                  />
                  {/* <img className={classes.temLogo} src="/logo.png" alt="logo" /> */}
                  {/* CaseConverge */}
                </Typography>
                <Grid style={{ display: "flex", flexGrow: 1 }}>
                  {this.props.isReadOnly && <IsReadOnlyAlert />}
                </Grid>
                <Grid className="d-flex flex-row">
                  {/* <Grid item>
                    <IconButton onClick={this.handlePopover}>
                      <BellOutline />
                    </IconButton>
                    <Popover
                      id="simple-popover"
                      open={this.state.openPopOver}
                      anchorEl={this.state.anchorE1}
                      onClose={this.handleClosePopover}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Paper className="p-16">
                        <List
                          component="nav"
                          aria-label="secondary mailbox folders"
                        >
                          {this.state.notifications.length > 0 ? (
                            this.state.notifications.map((ele, index) => (
                              <ListItem key={index} button>
                                <Typography><Typography variant="h6">{ele.title}</Typography> {ele.description} </Typography>
                              </ListItem>
                            ))
                          ) : (
                            <Typography>No notifications</Typography>
                          )}
                        </List>
                      </Paper>
                    </Popover>
                  </Grid> */}
                  <Grid item>
                    {/* <Hidden smDown>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => this.sendEmail()}
                      >
                        Feedback
                      </Button>
                    </Hidden> */}

                    {/* {this.state.notifications &&
                      this.state.notifications.length > 0 && (
                        <IconButton
                          size="small"
                          onClick={()=>this.handleNotificationsDialog(true)}
                          style={{ marginLeft: 2, marginRight: 2 }}
                        >
                          <Badge color="secondary" variant="dot">
                            <Bell />
                          </Badge>
                        </IconButton>
                      )} */}
                    <Popover
                      open={Boolean(this.state.anchorE1ForNotifications)}
                      anchorEl={this.state.anchorE1ForNotifications}
                      onClose={this.handleCloseNotifications}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      PaperProps={{
                        elevation: 0,
                        style: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          marginTop: 1.5,
                          maxHeight: "540px",
                          width: "320px",
                        },
                      }}
                    >
                      <List>
                        {this.state.notifications &&
                          this.state.notifications.length > 0 &&
                          this.state.notifications.map(
                            (notification, notificationIndex) => (
                              <React.Fragment key={notificationIndex}>
                                <ListItem
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.onNotificationClick(notification.type)
                                  }
                                >
                                  <ListItemText
                                    primary={
                                      NOTIFICATION_TYPES[notification?.type]
                                    }
                                    secondary={notification?.message}
                                  />
                                </ListItem>
                                {/* {this.state.notifications.length !==
                                  notificationIndex + 1 && ( */}
                                <Divider component="li" />
                                {/* )} */}
                              </React.Fragment>
                            )
                          )}
                        {this.state.notifications &&
                          this.state.notifications.length > 0 && (
                            <Typography
                              color="primary"
                              component="li"
                              variant="caption"
                              style={{
                                textAlign: "center",
                                cursor: "pointer",
                                paddingTop: "8px",
                              }}
                              onClick={()=>{
                                this.handleNotificationsDialog(true);
                                this.handleCloseNotifications();
                              }}
                            >
                              Show all notifications
                            </Typography>
                          )}
                      </List>
                    </Popover>
                    <Button aria-describedby="simple-popover" color="inherit">
                      <AccountCircle /> &nbsp;&nbsp;{" "}
                      <span>{this.getUserName() || ""}</span>
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Drawer
              variant={
                this.props.width
                  ? this.props.width === "sm" || this.props.width === "xs"
                    ? "temporary"
                    : "permanent"
                  : "permanent"
              }
              classes={{
                paper: `${classes.drawerPaper} ${
                  !this.state.open &&
                  `${classes.drawerPaperClose} menu-collapsed`
                } `,
              }}
              open={this.state.open}
            >
              <div className={classes.toolbarIcon}>
                <IconButton onClick={this.handleDrawerClose}>
                  <ChevronLeft />
                </IconButton>
              </div>
              <Divider />

              <SideMenu />
            </Drawer>
          </>
        ) : (
          <React.Fragment>
            {/* <AppBar
              position="absolute"
              color="default"
              className={`${classes.appBar} header-not-authenticated`}
            >
              <Toolbar variant="dense">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <img
                      src="images/she-safe-logo.png"
                      alt=""
                      className="logo"
                    />
                  </Grid>
                  <Grid item className="fs-20">
                    <Link
                      component="a"
                      color="inherit"
                      href="/"
                      target="_blank"
                    >
                      Home
                    </Link>
                    &nbsp; &nbsp;
                    <Link
                      component="a"
                      color="inherit"
                      href="/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </Link>
                    &nbsp; &nbsp;
                    <Link
                      component="a"
                      color="inherit"
                      href="/terms-of-use"
                      target="_blank"
                    >
                      Terms and conditions
                    </Link>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar> */}
          </React.Fragment>
        )}
        
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    logoImage: {
      height: 48,
      marginRight: 16,
      marginTop: 8,
      marginBottom: 8,
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      ...theme.mixins.toolbar,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      minHeight: "48px !important",
    } as any,
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: "none",
      border: "1px solid #ddd",
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
    },
    temLogo: {
      // marginTop: 4,
      // marginTop: 8,
      height: 30,
      marginRight: 16,
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      height: "100vh",
      overflow: "auto",
    },
    chartContainer: {
      marginLeft: -22,
    },
    tableContainer: {
      height: 320,
    },
    h5: {
      marginBottom: theme.spacing(2),
    },
  });
export default withStyles(styles)(
  withWidth()(withIsReadOnlyContext(withRouter(NavBar)))
);
