import * as React from "react";
import { useState, useEffect } from "react";
import Loading from "../common/Loading";
import { getQueryParams, setToken } from "../../services/UtilService";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface VerifyMainProps extends RouteComponentProps {}

const VerifyMain: React.FunctionComponent<VerifyMainProps> = (props) => {

  useEffect(() => {
    const params = getQueryParams();
    let token = params['token'];
    if(token){
        setToken(token);
        props.history.push("/dashboard");
    }else{
        props.history.push("/");
    }
  }, []);

  return <React.Fragment>
    <Loading/>
  </React.Fragment>;
};

export default withRouter(VerifyMain);
