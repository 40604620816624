import {
  IStandardAPIResponse,
  ICasesObj,
  IAttentionTimesheetsDetailsResponse,
  ICasesSummaryObj,
  IAttentionCaseDetails,
  IPlaintiffSearchResponse,
} from "../vm";
import { httpClient } from "./UtilService";

export async function getAllCasesList(
  searchObj: any
): Promise<IStandardAPIResponse<ICasesObj>> {
  try {
    let res = await httpClient<ICasesObj>(`cases/details`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getAllCasesSummaryList(
  searchObj: any
): Promise<IStandardAPIResponse<ICasesSummaryObj>> {
  try {
    let res = await httpClient<ICasesSummaryObj>(`cases/summary`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getCasesFiltersCount(searchObj?): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`cases/filters`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getAllCasesAttentionList(
  searchObj: any
): Promise<IStandardAPIResponse<IAttentionCaseDetails[]>> {
  try {
    let res = await httpClient<IAttentionCaseDetails[]>(`cases/attention-details`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getPlaintiffNames(searchText: string): Promise<IStandardAPIResponse<IPlaintiffSearchResponse[]>> {
  try {
    let res = await httpClient<IPlaintiffSearchResponse[]>(`cases/search/plaintiff`, "POST", { searchText });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getFirmNames(searchText: string): Promise<IStandardAPIResponse<string[]>> {
  try {
    let res = await httpClient<string[]>(`cases/search/firm`, "POST", { searchText });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getCompliantNames(searchText: string): Promise<IStandardAPIResponse<string[]>> {
  try {
    let res = await httpClient<string[]>(`cases/search/complaint`, "POST", { searchText });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}