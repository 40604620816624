import * as React from "react";
import { isTokenExpired } from "./services/UtilService";
import { ClassProps } from "./vm";
import {
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import { AuthenticatedRoute } from "./components/common/AuthenticatedRoute";
import LoginPage from "./pages/auth/LoginPage";
import { createStyles, Theme, withStyles } from "@material-ui/core";
import Page404 from "./pages/common/Page404";
import DashboardPage from "./pages/dashboard/DashboardPage";
import AccessManagementMain from "./components/access-management/AccessManagementMain";
import { URL_PATHS } from "./Constant";
import AboutMain from "./components/about/AboutMain";
import LoginsHistory from "./components/logins-history/LoginsHistory";
import AcceptableUsePolicyPage from "./pages/static/AcceptableUsePolicyPage";
import EndUserLicenseAgreementPage from "./pages/static/EndUserLicenseAgreementPage";
import CaseDetailMain from "./components/cases/CaseDetailMain";
import CaseSummaryMain from "./components/cases/CaseSummaryMain";
import CaseCompliance from "./components/cases/CaseCompliance";
import CaseSummaryViewMain from "./components/cases/CaseSummaryViewMain";
import NotesMain from "./components/notes/NotesMain";
import CaseAttentionDetailsMain from "./components/cases/CaseAttentionDetailsMain";
import VerifyPage from "./pages/auth/VerifyPage";
import VerifyAccountMain from "./components/auth/VerifyAccountMain";
import ForgotPasswordMain from "./components/forgot-password/ForgotPasswordMain";

export interface RoutesMainProps extends RouteComponentProps, ClassProps { }

const RoutesMain: React.FC<RoutesMainProps> = ({ classes, location }) => {
  let hasTokenExpired = isTokenExpired();
  return (
    <main className={`${classes.content} ${(hasTokenExpired || location.pathname.indexOf(URL_PATHS.DB_DOES_NOT_EXISTS) > -1) ? "un-auth-pages" : "mt-50"}`}>
      <Switch>
        <Route path="/" exact component={LoginPage} />
        <Route path="/verify" exact component={VerifyPage} />
        <Route path="/verify/account" exact component={VerifyAccountMain} />
        <Route path="/forgot-password" exact component={ForgotPasswordMain} />
        <Route path="/acceptable-use-policy" exact component={AcceptableUsePolicyPage} />
        <Route path="/end-user-license-agreement" exact component={EndUserLicenseAgreementPage} />
        <AuthenticatedRoute path="/dashboard" exact component={DashboardPage} /> 
        <AuthenticatedRoute
          path="/cases-summary"
          exact
          component={CaseSummaryMain}
        />
        <AuthenticatedRoute
          path="/cases-summary/case"
          exact
          component={CaseSummaryViewMain}
        />
        <AuthenticatedRoute
          path="/case-detail"
          exact
          component={CaseDetailMain}
        />
        <AuthenticatedRoute
          path="/notes"
          exact
          component={NotesMain}
        />
        <AuthenticatedRoute
          path="/attention-case-details"
          exact
          component={CaseAttentionDetailsMain}
        /> 
        <AuthenticatedRoute
          path="/access-management"
          exact
          component={AccessManagementMain}
        />
        <AuthenticatedRoute
          path="/case-compliance"
          exact
          component={CaseCompliance}
        />
        <AuthenticatedRoute
          path="/about"
          exact
          component={AboutMain}
        />
        <AuthenticatedRoute
          path="/logins-history"
          exact
          component={LoginsHistory}
        />



        {/* Dummy routes */}
        <AuthenticatedRoute
          path="/configure-firms"
          exact
          component={CaseAttentionDetailsMain}
        /> 
        <AuthenticatedRoute
          path="/import"
          exact
          component={CaseAttentionDetailsMain}
        /> 
        <AuthenticatedRoute
          path="/templates"
          exact
          component={CaseAttentionDetailsMain}
        /> 
        <AuthenticatedRoute
          path="/reports/:id/details"
          exact
          component={CaseAttentionDetailsMain}
        /> 
        <AuthenticatedRoute
          path="/setup-firms"
          exact
          component={CaseAttentionDetailsMain}
        /> 
        <AuthenticatedRoute
          path="/setup-plaintiff-details"
          exact
          component={CaseAttentionDetailsMain}
        /> 




        <Route path="" exact component={Page404} />
      </Switch>
    </main>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    appBarSpacer: { height: 16 },
    content: {
      flexGrow: 1,
      height: "calc(100vh - 50px)",
      overflow: "auto",
      //   padding: 8,
      //   [theme.breakpoints.down("sm")]: {
      //     padding: 8,
      //   },
      backgroundColor: "#fbfbfb",
    },
  });

export default withStyles(styles)(withRouter(RoutesMain));
