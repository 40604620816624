import {
  createStyles,
  TableCell,
  TableRow,
  Theme,
  withStyles,
} from "@material-ui/core";
import { API_URL, IS_READ_ONLY_TOAST_MESSAGE, MAIN_APP_DOMAINS } from "../Constant";
import { IStandardAPIResponse } from "../vm";
import * as React from "react";


export const setToken = (token: string) => {
  localStorage.setItem("temCasesToken", token);
};

export const removeToken = () => {
  localStorage.removeItem("temCasesToken");
};

export function getToken() {
  let res = localStorage.getItem("temCasesToken");
  if (res === null || res === undefined) {
    return "";
  }
  return res;
}
export const parseJwt = (tokenParsed?: string) => {
  let token;
  if (!tokenParsed) {
    token = getToken();
  } else {
    token = tokenParsed;
  }
  if (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }
  return undefined;
};

export const httpClient = async <T>(
  url: string,
  type: string,
  obj: any = undefined,
  formData: boolean = false,
  toCheckIsReadOnly: boolean = false,
  isCustomURL: boolean = false,
): Promise<IStandardAPIResponse<T>> => {
  try {
    type = type.toUpperCase();
    if (!formData) {
      if (type.toLowerCase() === "get" && obj) {
        var params = Object.keys(obj)
          .map(function (key) {
            return key + "=" + obj[key];
          })
          .join("&");
        url += "?" + params;
        obj = undefined;
      }
      if (obj) {
        obj = removeNulls({ ...obj });
      }
    }
    let domainDetails = getHostDetails();
    let subDomain = domainDetails?.subdomain;
    if (subDomain && !domainDetails.isIp) {
      subDomain = subDomain.split(".")[0];
    }
    let headers = {
      "Content-Type": "application/json; charset=utf-8",
      Authorization:
        "Bearer " +
        (obj && obj.isUpdateToken && obj.token ? obj.token : getToken()),
      dbName: subDomain || ""
    };
    if (formData) {
      delete headers["Content-Type"];
    }
    let isNotReadOnly = true;
    // let readOnlyResult;
    // if (toCheckIsReadOnly) {
    //   readOnlyResult = await checkIsReadOnlyApi();
    //   if (readOnlyResult?.isSuccess && readOnlyResult.data?.isReadonly) {
    //     isNotReadOnly = false;
    //   }
    // }
    if (isNotReadOnly) {
      let res = await fetch(isCustomURL ? url : API_URL + url, {
        method: type.toUpperCase(),
        body: formData ? obj : JSON.stringify(obj),
        headers: isCustomURL ? undefined : headers,
      });
      return await res.json();
    } else {
      return { isReadOnly: true, isSuccess: true, success: true, message: IS_READ_ONLY_TOAST_MESSAGE, data: undefined, };
    }
  } catch (error) {
    console.group(`API ${type} Error`);
    console.error(error);
    console.groupEnd();
    throw error;
  }
};

export const getHostDetails = () => {
  var result: any = {};
  let hostName = window.location.hostname;
  if (hostName === 'localhost') return { subdomain: 'localhost' };
  let reg = /(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])/;
  if (reg.test(hostName)) {
    result.subdomain = hostName;
    result.isIp = true;
  } else {
    var regexParse = new RegExp('([a-z\-0-9]{2,63})\.([a-z\.]{2,5})$');
    var urlParts = regexParse.exec(window.location.hostname);
    result.domain = urlParts[1];
    result.type = urlParts[2];
    result.subdomain = window.location.hostname.replace(result.domain + '.' + result.type, '').slice(0, -1);
    if(MAIN_APP_DOMAINS.indexOf(result.subdomain)>-1){
      result.subdomain = ""
    }
    result.isIp = false;
  }
  return result;
}

export const httpCustomUrlClient = async <T>(
  url: string,
  type: string,
  obj: any = undefined
) => {
  try {
    type = type.toUpperCase();
    if (type.toLowerCase() === "get" && obj) {
      var params = Object.keys(obj)
        .map(function (key) {
          return key + "=" + obj[key];
        })
        .join("&");
      url += "?" + params;
      obj = undefined;
    }

    await fetch(url, {
      method: type.toUpperCase(),
      body: obj,
    });
    return null;
  } catch (error) {
    console.group(`Custom API ${type} Error`);
    console.error(error);
    console.groupEnd();
    throw error;
  }
};

export const httpDownloadCSV = async<T>(
  url: string,
  type: string,
  obj: any = undefined,
  isDownload?: boolean
) => {
  try {
    if (obj) {
      obj = removeNulls({ ...obj });
    }
    let result = await fetch(`${API_URL}${url}`, {
      method: type,
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + (getToken() || ""),
        dbName: getSubDomain(),
      },
    });
    let csv = await result.blob();
    const csvFile = await new Blob([csv], { type: "application/csv" });
    const csvFileURL = await URL.createObjectURL(csvFile);
    if (isDownload !== false) {
      var a: any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display:none";
      a.href = csvFileURL;
      a.download = `Time Entry Summary.csv`;
      a.click();
    }
  } catch (error) {
    console.group(`Custom API ${type} Error`);
    console.error(error);
    console.groupEnd();
  }
};
export const httpDownloadPDF = async<T>(
  url: string,
  type: string,
  obj: any = undefined,
  isDownload?: boolean
) => {
  try {
    if (obj) {
      obj = removeNulls({ ...obj });
    }
    let result = await fetch(`${API_URL}${url}`, {
      method: type,
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + (getToken() || ""),
      },
    });
    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], { type: "application/pdf" });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    if (isDownload !== false) {
      var a: any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display:none";
      a.href = pdfFileURL;
      a.download = `Detailed Time Entries.pdf`;
      a.click();
    }
  } catch (error) {
    console.group(`Custom API ${type} Error`);
    console.error(error);
    console.groupEnd();
  }
};


export const isTokenExpired = () => {
  var token = getToken();
  if (token) {
    let user = parseJwt(token);
    var cur_time = new Date().getTime() / 1000;
    if (user && user.exp && cur_time < user.exp) {
      return false;
    }
    return true;
  } else {
    return true;
  }
};

export const isValidateEmail = (email: string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function debounce<F extends (...params: any[]) => void>(
  fn: Function,
  delay: number
) {
  let timeoutID: any = null;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
  } as F;
}

export const StyledTableCell = withStyles((theme: Theme) => {
  return createStyles({
    head: {
      backgroundColor: "#cccccc",
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  });
})(TableCell);

export const StyledTableRow = withStyles((theme: Theme) => {
  return createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  });
})(TableRow);

export const useUnload = (fn: any) => {
  const cb = React.useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  React.useEffect(() => {
    cb.current = fn;
  }, [fn]);

  React.useEffect(() => {
    const onUnload = (...args: any) => cb.current?.(...args);

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};

export const abbreviateNumber = (value: number, fixed?: number, isTwoDecimals?: boolean) => {
  if (value === null) { return null; } // terminate early
  if (value === 0) { return isTwoDecimals ? "0.00" : '0'; } // terminate early
  fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
  var b = (value).toPrecision(2).split("e"), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(Number(b[1].slice(1)), 14) / 3), // floor at decimals, ceiling at trillions
    c = k < 1 ? Number(value.toFixed(0 + fixed)) : Number((value / Math.pow(10, k * 3)).toFixed(1 + fixed)), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = (isTwoDecimals ? d.toFixed(2) : d) + ['', 'K', 'M', 'B', 'T'][k];
  return e;
};

export const removeNulls = (obj: any) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === "object") {
      removeNulls(obj[key]);
    } else if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
      delete obj[key];
    }
  });
  return obj;
};

export const currencyFormat = (amount: any) => {
  if (isNaN(amount)) {
    return amount;
  }
  let newValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(amount));
  return newValue
};

export const numberFormat = (number: any) => {
  if (isNaN(number)) {
    return number;
  }
  let decimalNumber = "";
  if (number && number.toString().indexOf(".") > -1) {
    number = Number(number).toFixed(2);
    decimalNumber = number.split(".").pop();
    number = number.split(".")[0];
  }
  let newValue = new Intl.NumberFormat('en-US').format(number);
  return decimalNumber ? newValue + "." + decimalNumber : newValue;
};

export const convertToFixed = (number, isToFixed = false) => {
  if (isNaN(number)) {
    return number
  }
  if (number && number.toString().indexOf(".") > -1) {
    return Number(number).toFixed(2);
  }
  if (isToFixed && number) {
    return Number(number).toFixed(2);
  }
  return number;
}

export const hoursFormat = (number: any) => {
  if (isNaN(number)) {
    return number;
  }
  number = Math.floor(number * 10) / 10;

  number = new Intl.NumberFormat('en-US').format(number);

  if (number.toString().indexOf(".") === -1) {
    number = number.toString() + ".0";
  }

  return number;
};

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
}

export const arrayUniqueByKey = (array: any[], key: string) => {
  let arr = array.reduce((acc, item) => {
    if (acc.map(x => x[key]).indexOf(item[key]) === -1) {
      acc.push(item);
    }
    return acc;
  }, []);
  return arr;
};

export const getSubDomain = () => {
  let domainDetails = getHostDetails();
  let subDomain = domainDetails?.subdomain;
  if (subDomain && !domainDetails.isIp) {
    subDomain = subDomain.split(".")[0];
  }
  return subDomain || "";
};

export const generateRandomUUID = () => {
  return (new Date()).getTime().toString(36) + Math.random().toString(36).slice(2)
}

export const getQueryParams = () => {
  var urlParams: any = {};
  if (window.location.search) {
    let search = window.location.search.replace("?", "");
    if (search) {
      let params = search.split("&");
      params.forEach((element) => {
        let searchQuery = element.split("=");
        if (searchQuery && searchQuery.length > 1) {
          urlParams[searchQuery[0]] = searchQuery[1];
        }
      });
    }
  }
  return urlParams;
};

export const getCeilValueForTotalCount = (value: number) => {
  value = Math.ceil(value / 10) * 10;
  return value;
}