import {
  Button,
  debounce,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Formik, FormikErrors } from "formik";
import * as React from "react";
import { USER_ROLES } from "../../Constant";
import { registerAUser, updateAUser } from "../../services/UserService";
import { IUser, IFirm } from "../../vm";
import CustomDrawer from "../common/CustomDrawer";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import AsyncSelect from "react-select/async";
import { getAllFirms, getUserFirms } from "../../services/FirmsService";
import { getHostDetails, parseJwt } from "../../services/UtilService";
import { EyeOffOutline, EyeOutline } from "mdi-material-ui";
export interface ManageAccessManagementDialogProps {
  data?: IUser;
  handleDialogClose: Function;
  updateStatus: Function;
}

const ManageAccessManagementDialog: React.SFC<ManageAccessManagementDialogProps> = ({
  data,
  handleDialogClose,
  updateStatus
}) => {
  let hostDetails = getHostDetails();
  const [state, setState] = React.useState({
    user: {
      userName: "",
      email: "",
      password: "",
      role: parseJwt().Role === "2" ? 2 : 0,
      firms: data?.userFirms || [],
      isEnabled: true,
      showPassword: false,
      caseName: hostDetails?.subdomain,
      ...data,
    },
    firmsList: [],
    selectedFirms: [],
    tokenData: parseJwt(),
  } as {
    user: IUser;
    firmsList: { label: string; value: number; visited: boolean }[];
    selectedFirms: { label: string; value: number; visited: boolean }[];
    tokenData: any;
  });

  const [isLoading, setLoading] = React.useState(false);
  const [userFirms, setUserFirms] = React.useState([]);
  const { showToast } = React.useContext(ToastContext);

  const closeAddDialog = (data?: IUser) => {
    handleDialogClose(data);
  };

  // React.useEffect(() => {
  //   const fetchFirms = async () => {
  //     if (Number(state.tokenData.Role) === 2) {
  //       await getUserFirmsList();
  //     } else {
  //       await getFirmsList();
  //     }
  //   };
  //   fetchFirms();
  // }, []);

  const styles = {
    multiValueRemove: (base, state) => {
      return state.data.isDisabled ? { ...base, display: "none" } : base;
    },
  };

  const getFirmsList = async (userFirmsList?: any) => {
    setLoading(true);
    let result = await getAllFirms();
    setLoading(false);
    if (result?.isSuccess) {
      let list: any = [];
      let selectedFirms: any = [];
      if (result?.data.length > 0) {
        list = result.data.map((ele) => {
          return {
            value: ele.lawFirmCode,
            label: ele.lawFirmShortName,
            isDisabled:
              state.tokenData.Role === "2"
                ? isFirmDisabled(ele.lawFirmCode, userFirmsList)
                : false,
            visited:
              state.tokenData.Role === "2"
                ? isFirmDisabled(ele.lawFirmCode, userFirmsList)
                : false,
          };
        });
        if (data && data.userFirms && data.userFirms.length > 0) {
          selectedFirms = data.userFirms.map((ele) => {
            let item: any = result.data.find((e) => e.lawFirmCode === ele);
            return {
              value: item.lawFirmCode,
              label: item.lawFirmShortName,
              isDisabled:
                state.tokenData.Role === "2"
                  ? isFirmDisabled(item.lawFirmCode, userFirmsList)
                  : false,
              visited:
                state.tokenData.Role === "2"
                  ? isFirmDisabled(item.lawFirmCode, userFirmsList)
                  : false,
            };
          });
        }
      }
      setState({
        ...state,
        firmsList: [...state.firmsList, ...list],
        selectedFirms,
      });
    } else {
      showToast(
        result?.message ? result.message : "Error while fetching firms",
        "error"
      );
    }
  };

  const isFirmDisabled = (firmCode: number, userFirmsList) => {
    let firms = [...userFirmsList];
    let index = firms.findIndex((ele) => ele.lawFirmCode === firmCode);
    if (index === -1) {
      return true;
    } else {
      return false;
    }
  };

  const getUserFirmsList = async () => {
    setLoading(true);
    let result = await getUserFirms(Number(state.tokenData.CustomerId));
    setLoading(false);
    if (result?.isSuccess) {
      setUserFirms(result?.data || []);
      getFirmsList(result?.data || []);
    } else {
      showToast(
        result?.message ? result.message : "Error while fetching firms",
        "error"
      );
    }
  };

  const searchFirms = async (input: any, callback: Function) => {
    if (
      input === null ||
      input === undefined ||
      input === "" ||
      input.trim().length <= 1
    ) {
      callback(null, { options: [] });
    } else {
      let communityOptions = [...state.firmsList];
      callback(
        communityOptions.filter((i) =>
          i.label.toLowerCase().includes(input.toLowerCase())
        )
      );
    }
  };
  const onFirmChange = (newValue: any, values: IUser) => {
    let firms: any = [];
    if (newValue) {
      newValue.forEach((element: any) => {
        firms.push(element.value);
      });
    } else {
      firms = [];
    }
    setState({
      ...state,
      user: {
        ...values,
        firms,
      },
      selectedFirms: newValue ? newValue : [],
    });
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <CustomDrawer
        title={state.user.id ? "Edit User" : "Add User"}
        onClose={() => closeAddDialog()}
      >
        <section className="p-16">
          <Formik
            enableReinitialize
            initialValues={state.user}
            validate={(values) => {
              let errors: FormikErrors<IUser> = {};
              if (!values.userName || !values.userName.replace(/\s+/g, "")) {
                errors.userName = "Required";
              }
              if (!values.email) {
                errors.email = "Required";
              }
              if (!values.id && !values.password) {
                errors.password = "Required";
              }
              if (
                !values.id &&
                values.password &&
                values.password.length <= 4
              ) {
                errors.password = "Need to be more than 4 characters";
              }
              if (
                values.email &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={async (values: IUser, { setSubmitting }) => {
              setLoading(true);
              const obj = { ...values };
              console.log("Values", obj);
              if (obj.id) {
                delete obj.password;
              }
              if (obj.role !== 2) {
                delete obj.firms;
              }
              if (obj.userFirms) {
                delete obj.userFirms;
              }
              obj.firms = [...(state.user.firms as number[])];
              // applying trim fnc
              obj.userName = obj.userName.trim();
              obj.email = obj.email.trim();
              let res: any;
              if (obj.id) {
                res = await updateAUser(obj);
              } else {
                res = await registerAUser(obj);
              }
              setLoading(false);
              if (res.isSuccess) {
                if (res?.isReadOnly) {
                  showToast(res.message, "error");
                  closeAddDialog();
                  updateStatus(res.isReadOnly);
                } else {
                  showToast(
                    `User ${values.id ? "updated" : "created"} successfully`,
                    "success"
                  );
                  if (!obj.id) {
                    // let tokenData = parseJwt(res.data);
                    closeAddDialog({
                      ...obj,
                      firms: [...(state.user.firms as number[])],
                      userFirms: [...(state.user.firms as number[])],
                      id: res.data.id,
                    });
                  } else {
                    closeAddDialog({
                      ...res.data,
                      firms: [...(state.user.firms as number[])],
                      userFirms: [...(state.user.firms as number[])],
                    });
                  }
                }
              } else {
                showToast(
                  res.message
                    ? res.message
                    : `Error while ${values.id ? "updating" : "creating"} user`,
                  "error"
                );
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        id="userName"
                        name="userName"
                        label="User Name"
                        onChange={handleChange}
                        error={
                          errors.userName && touched.userName ? true : false
                        }
                        helperText={
                          errors.userName && touched.userName && errors.userName
                        }
                        onBlur={handleBlur}
                        value={values.userName}
                      />
                    </FormControl>
                  </Grid>
                  {!state.user.id && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          id="password"
                          name="password"
                          type={values.showPassword ? "text" : "password"}
                          label="Password"
                          // onChange={handleChange}
                          onChange={(event) => {
                            let value = event.target.value;
                            value = value.replace(/\s+/g, "");
                            setFieldValue("password", value);
                          }}
                          error={
                            errors.password && touched.password ? true : false
                          }
                          helperText={
                            errors.password &&
                            touched.password &&
                            errors.password
                          }
                          onBlur={handleBlur}
                          value={values.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  name="showPassword"
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    setFieldValue(
                                      "showPassword",
                                      !values.showPassword
                                    );
                                  }}
                                >
                                  {values.showPassword ? (
                                    <EyeOutline />
                                  ) : (
                                    <EyeOffOutline />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        id="email"
                        name="email"
                        type="email"
                        label="Email"
                        onChange={handleChange}
                        error={errors.email && touched.email ? true : false}
                        helperText={
                          errors.email && touched.email && errors.email
                        }
                        onBlur={handleBlur}
                        value={values.email}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel className="select-label" htmlFor="role">
                        Role
                      </InputLabel>
                      <Select
                        value={values.role}
                        name="role"
                        onChange={(event: any) => {
                          let value = event.target.value;
                          setFieldValue("role", Number(value));
                        }}
                        onBlur={handleBlur}
                      >
                        {Object.keys(USER_ROLES).map((x, index) => (
                          <MenuItem
                            value={x}
                            key={`user_role_${index}`}
                            disabled={state.tokenData.Role === "2" && x !== "2"}
                          >
                            {USER_ROLES[x]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {values.role === 2 && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <AsyncSelect
                        isSearchable
                        value={state.selectedFirms}
                        className="async-select react-select react-select-overflow-top"
                        placeholder="Search for firms"
                        autoload={true}
                        isMulti
                        styles={styles}
                        isClearable={
                          !state.selectedFirms.some((client) => client.visited)
                        }
                        loadOptions={debounce(searchFirms, 500)}
                        defaultOptions={state.firmsList}
                        onChange={(newValue) => onFirmChange(newValue, values)}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid container spacing={2} justify="flex-end">
                      <Grid item>
                        <Button variant="text" onClick={() => closeAddDialog()}>
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {values.id ? "Update" : "Add"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </section>
      </CustomDrawer>
    </React.Fragment>
  );
};

export default ManageAccessManagementDialog;
